.tree-container { 
    width: 67%;
    margin-top: 100px;
    margin-bottom: 100px;
}

.tree-header {
    color: #444645;
    font-weight: 800;
    font-size: 48px;
}

.tree-subtitle {
    color: #5A605F;
    font-weight: 200;
    font-size: 24px;
}

.tree-content-container {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
}

.tree-content-branch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -20px;
    margin-bottom: -15px;
}

.branch-border {
    border: double 1px transparent;
    border-radius: 24px;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    height: 144px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
}

.branch-border-left {
    background-image: linear-gradient(white, white), 
                        linear-gradient(to left, #C2DAF5, #C2DAF500);
    padding-right: 30px;
}

.branch-border-right {
    background-image: linear-gradient(white, white), 
                        linear-gradient(to right, #C2DAF5, #C2DAF500);
    padding-left: 30px
}

.animation1-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation1-appear.animation1-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
}

.animation2-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation2-appear.animation2-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
    transition-delay: 250ms; 
}

.animation3-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation3-appear.animation3-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ; 
    transition-delay: 500ms;
}

.animation4-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation4-appear.animation4-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
    transition-delay: 1000ms;
}

.animation5-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation5-appear.animation5-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
    transition-delay: 1250ms; 
}

.animation6-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation6-appear.animation6-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ; 
    transition-delay: 1500ms;
}

.animation7-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation7-appear.animation7-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
    transition-delay: 2000ms;
}

.animation8-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation8-appear.animation8-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
    transition-delay: 2250ms; 
}

.animation9-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation9-appear.animation9-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ; 
    transition-delay: 2500ms;
}

.animation10-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation10-appear.animation10-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
    transition-delay: 3000ms;
}

.animation11-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation11-appear.animation11-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
    transition-delay: 3250ms; 
}

.animation12-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation12-appear.animation12-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ; 
    transition-delay: 3500ms;
}

.animation13-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation13-appear.animation13-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
    transition-delay: 4000ms;
}

.animation14-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation14-appear.animation14-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ;
    transition-delay: 4250ms; 
}

.animation15-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation15-appear.animation15-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ; 
    transition-delay: 4500ms;
}

.animation16-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation16-appear.animation16-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ; 
    transition-delay: 750ms;
}

.animation17-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation17-appear.animation17-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ; 
    transition-delay: 1750ms;
}

.animation18-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation18-appear.animation18-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ; 
    transition-delay: 2750ms;
}

.animation19-appear {
    opacity: 0;
    z-index: 1;
}
  
.animation19-appear.animation19-appear-active {
    opacity: 1;
    transition: opacity 200ms linear ; 
    transition-delay: 3750ms;
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .tree-content-branch {
        margin-top: -25px;
        margin-bottom: -23px;
    }
    .tree-container { 
        width: 80%;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .tree-header {
        font-size: 38px;
    }
    .tree-subtitle {
        font-size: 16px;
    }
}

@media screen and (min-width: 429px) and  (max-width: 768px) {
    .tree-container { 
        width: 80%;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .tree-header {
        font-size: 40px;
    }
    .tree-subtitle {
        font-size: 16px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
}



/* CONTROLLING THE WIDTH OF THE CONTENT TREE */

@media screen and (min-width: 1500px) {
    .tree-container { 
        width: 65%;
    }
}

@media screen and (min-width: 1800px) {
    .tree-container { 
        width: 55%;
    }
}

@media screen and (min-width: 2000px) {
    .tree-container { 
        width: 45%;
    }
}