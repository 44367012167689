.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.3s ease; 
    width: 363px;
    border: 1px solid #E3ECFB;
    border-radius: 24px;
    margin-left: 25px;
    margin-right: 25px;
    height: 257px;
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    /*cursor: pointer;*/
}

.card.fade-out {
    border: 0px;
}

.culture-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.culture-container-top {
    padding-left: 3.5%;
    padding-right: 3.5%;
}

.culture-container-bottom {
    margin-top: 25px;
    padding-left: 17%;
    padding-right: 17%;
}

.culture-card-title {
    font-size: 24px;
    font-weight: 400;
    color: #5A605F;
}

.culture-card-text {
    color: black;
    font-size: 16px;
    font-weight: 200;
    width: 70%;
    height: 0px;
    transition: height 100ms ease-in-out;
    text-align: left;
}

/*test*/
  
.image {
    transition: opacity 100ms ease-in-out;
}
  
.fade-out {
    opacity: 0;
}
  
.fade-in {
    opacity: 1;
}

.card:hover > .culture-card-text {
    height: 50px;
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .culture-card-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 100px;
        margin-right: 100px;
    }
    .culture-container-bottom {
        margin-top: 0px;
        padding-left: 17%;
        padding-right: 17%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .culture-card-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 100px;
        margin-right: 100px;
    }
    .culture-container-bottom {
        margin-top: 0px;
        padding-left: 17%;
        padding-right: 17%;
    }
}

@media screen and (min-width: 429px) and  (max-width: 768px) {
    .culture-card-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 100px;
        margin-right: 100px;
    }
    .culture-container-bottom {
        margin-top: 0px;
        padding-left: 17%;
        padding-right: 17%;
    }
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .culture-card-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 100px;
        margin-right: 100px;
    }
    .culture-container-bottom {
        margin-top: 0px;
        padding-left: 17%;
        padding-right: 17%;
    }
    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: box-shadow 0.3s ease; 
        width: 283px;
        border: 1px solid #E3ECFB;
        border-radius: 24px;
    }
}