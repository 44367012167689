.curved-top {
    width: 120%;
    height: 100%;
    background-color: #2A2C2C;
    position: relative;
    overflow: hidden;
    margin-top: 500px;
    border-top-left-radius: 100%450px;
    border-top-right-radius: 100%450px; 
}

.footer-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer-text {
    color: #F5F6F6;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
}

.footer-text-right {
    color: #6A706F;
    font-size: 16px;
    font-weight: 400;
    text-align: right;
    cursor: pointer;
}

.p-margin {
    margin-top: -10px;
}

.u-margin {
    margin-top: 15px;
}

.footer-rounded {
    position: absolute;
    z-index: 1;
    bottom: 3.5%;
    left: 11%;
    border-radius: 24px;
    padding: 10px;
    width: 78%;
    background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 60px;
}

.footer-title {
    font-size: 48px;
    font-weight: 800;
    text-align: left;
}

.footer-subtitle {
    font-size: 24px;
    font-weight: 200;
    text-align: left;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.footer-schedule-btn {
    padding: 20px;
    height: 48px;
    width: 210px;
    border-radius: 12px;
    background-color: white;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 60px;
    margin-top: 20px;
    transition: width 0.3s, height 0.3s, box-shadow 0.3s;
}

.footer-schedule-btn:hover {
    width: 225px;
    height: 53px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.footer-schedule-btn-wrapper {
    max-height: 53px;
    min-height: 53px;
    margin-bottom: 80px;
    width: fit-content;
}

.footer-btn-text {
    color: #FF2F48;
    font-size: 16px;
    font-weight: 800;
}

.footer-certificates {
    margin-right: 60px;
}

.footer-top-logo {
    margin-left: 60px;
    height: 67.5px;
}

.footer-bottom-logo {
    margin-left: 250px;
    cursor: pointer;
}

.footer-title-wrapper {
    margin-left: 60px;
    margin-right: 60px;
    margin-top: -20px;
}

.footer-subtitle-wrapper {
    margin-left: 60px;
    width: 58%;
    margin-top: -45px;
}

.footer-address {
    margin-right: 250px;
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .curved-top {
        width: 120%;
        height: 100%;
        background-color: #2A2C2C;
        position: relative;
        overflow: hidden;
        margin-top: 1280px;
        border-top-left-radius: 100%450px;
        border-top-right-radius: 100%450px; 
    }
    .footer-content {
        height: 100%;
        width: 101%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (min-width: 828px) and (max-width: 1024px) {
    .curved-top {
        width: 120%;
        height: 100%;
        background-color: #2A2C2C;
        position: relative;
        overflow: hidden;
        margin-top: 1430px;
        border-top-left-radius: 100%450px;
        border-top-right-radius: 100%450px; 
    }
    .footer-content {
        height: 100%;
        width: 101%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .footer-bottom-logo {
        margin-left: 10%;
    }
    .footer-certificates {
        margin-right: 20px;
    }  
    .footer-address {
        margin-right: 150px;
    }
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 5.8%;
        left: 11%;
        border-radius: 24px;
        padding: 10px;
        width: 78%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (min-width: 769px) and (max-width: 827px) {
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 7%;
        left: 11%;
        border-radius: 24px;
        padding: 10px;
        width: 78%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .curved-top {
        width: 120%;
        height: 100%;
        background-color: #2A2C2C;
        position: relative;
        overflow: hidden;
        margin-top: 1530px;
        border-top-left-radius: 100%450px;
        border-top-right-radius: 100%450px; 
    }   
    .logo-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
    .footer-top-logo {
        margin-left: 0px;
        height: 67.5px;
    }
    .footer-certificates {
        margin-right: 0px;
        margin-top: 50px;
    }  
    .footer-bottom-logo {
        margin-left: 0px;
        margin-bottom: 20px;
    }
    .footer-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 115px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .footer-text {
        color: #F5F6F6;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }   
    .footer-text-right {
        color: #6A706F;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }  
    .p-margin {
        margin-top: 0px;
    }   
    .u-margin {
        margin-top: 0px;
    }
    .footer-address {
        margin-right: 0px;
    }
}

@media screen and (min-width: 601px) and  (max-width: 768px) {
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 4.8%;
        left: 10%;
        border-radius: 24px;
        padding: 10px;
        width: 78%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .curved-top {
        width: 120%;
        height: 100%;
        background-color: #2A2C2C;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1230px;
        border-top-left-radius: 100%150px;
        border-top-right-radius: 100%150px; 
    }   
    .logo-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
    .footer-top-logo {
        margin-left: 0px;
        height: 67.5px;
    }
    .footer-certificates {
        margin-right: 0px;
        margin-top: 50px;
    }  
    .footer-bottom-logo {
        margin-left: 0px;
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .footer-content {
        height: 100%;
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-top: 250px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .footer-title-wrapper {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -20px;
    }
    .footer-subtitle-wrapper {
        margin-left: 20px;
        width: 90%;
        margin-top: -25px;
    }
    .footer-text {
        color: #F5F6F6;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }   
    .footer-text-right {
        color: #6A706F;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }  
    .p-margin {
        margin-top: 0px;
    }   
    .u-margin {
        margin-top: 0px;
    }
    .footer-address {
        margin-right: 0px;
    }
    .footer-title {
        font-size: 40px;
    }
    .footer-subtitle {
        font-size: 16px;
    }
    .footer-schedule-btn {
        width: 100%;
        margin-left: 20px;
    }
    .footer-schedule-btn-wrapper {
        width: 90%;
    }
    .footer-schedule-btn:hover {
        width: 100%;
        height: 53px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (min-width: 501px) and  (max-width: 600px) {
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 5%;
        left: 10%;
        border-radius: 24px;
        padding: 10px;
        width: 78%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .curved-top {
        width: 120%;
        height: 100%;
        background-color: #2A2C2C;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1480px;
        border-top-left-radius: 100%100px;
        border-top-right-radius: 100%100px; 
    }   
    .logo-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
    .footer-top-logo {
        margin-left: 0px;
        height: 67.5px;
    }
    .footer-certificates {
        margin-right: 0px;
        margin-top: 50px;
    }  
    .footer-bottom-logo {
        margin-left: 0px;
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .footer-content {
        height: 100%;
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-top: 250px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .footer-title-wrapper {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -20px;
    }
    .footer-subtitle-wrapper {
        margin-left: 20px;
        width: 90%;
        margin-top: -25px;
    }
    .footer-text {
        color: #F5F6F6;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }   
    .footer-text-right {
        color: #6A706F;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }  
    .p-margin {
        margin-top: 0px;
    }   
    .u-margin {
        margin-top: 0px;
    }
    .footer-address {
        margin-right: 0px;
    }
    .footer-title {
        font-size: 40px;
    }
    .footer-subtitle {
        font-size: 16px;
    }
    .footer-schedule-btn {
        width: 100%;
        margin-left: 20px;
    }
    .footer-schedule-btn-wrapper {
        width: 90%;
    }
    .footer-schedule-btn:hover {
        width: 100%;
        height: 53px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (min-width: 429px) and  (max-width: 500px) {
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 4.5%;
        left: 8%;
        border-radius: 24px;
        padding: 10px;
        width: 78%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .curved-top {
        width: 120%;
        height: 100%;
        background-color: #2A2C2C;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1480px;
        border-top-left-radius: 100%50px;
        border-top-right-radius: 100%50px; 
    }   
    .logo-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
    .footer-top-logo {
        margin-left: 0px;
        height: 67.5px;
    }
    .footer-certificates {
        margin-right: 0px;
        margin-top: 50px;
    }  
    .footer-bottom-logo {
        margin-left: 0px;
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .footer-content {
        height: 100%;
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-top: 250px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .footer-title-wrapper {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -20px;
    }
    .footer-subtitle-wrapper {
        margin-left: 20px;
        width: 90%;
        margin-top: -25px;
    }
    .footer-text {
        color: #F5F6F6;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }   
    .footer-text-right {
        color: #6A706F;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }  
    .p-margin {
        margin-top: 0px;
    }   
    .u-margin {
        margin-top: 0px;
    }
    .footer-address {
        margin-right: 0px;
    }
    .footer-title {
        font-size: 40px;
    }
    .footer-subtitle {
        font-size: 16px;
    }
    .footer-schedule-btn {
        width: 100%;
        margin-left: 20px;
    }
    .footer-schedule-btn-wrapper {
        width: 90%;
    }
    .footer-schedule-btn:hover {
        width: 100%;
        height: 53px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 5%;
        left: 8%;
        border-radius: 24px;
        padding: 10px;
        width: 78%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .curved-top {
        width: 120%;
        height: 100%;
        background-color: #2A2C2C;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1430px;
        border-top-left-radius: 100%50px;
        border-top-right-radius: 100%50px; 
    }   
    .logo-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
    .footer-top-logo {
        margin-left: 0px;
        height: 67.5px;
    }
    .footer-certificates {
        margin-right: 0px;
        margin-top: 50px;
    }  
    .footer-bottom-logo {
        margin-left: 0px;
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .footer-content {
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-top: 250px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .footer-title-wrapper {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: -20px;
    }
    .footer-subtitle-wrapper {
        margin-left: 0px;
        width: 90%;
        margin-top: -25px;
    }
    .footer-text {
        color: #F5F6F6;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }   
    .footer-text-right {
        color: #6A706F;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
    }  
    .p-margin {
        margin-top: 0px;
    }   
    .u-margin {
        margin-top: 0px;
    }
    .footer-address {
        margin-right: 0px;
    }
    .footer-title {
        font-size: 32px;
    }
    .footer-subtitle {
        font-size: 16px;
    }
    .footer-schedule-btn {
        width: 100%;
        margin-left: 5px;
    }
    .footer-schedule-btn-wrapper {
        width: 95%;
    }
    .footer-schedule-btn:hover {
        width: 100%;
        height: 53px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }
}



/* LARGER SCREENS */

@media screen and (min-width: 1280px) and (max-width: 1500px) {
    .footer-content {
        height: 100%;
        width: 98.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .footer-bottom-logo {
        margin-left: 16%;
    }
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 3.5%;
        left: 10.5%;
        border-radius: 24px;
        padding: 10px;
        width: 79%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
    .footer-content {
        height: 100%;
        width: 94.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .footer-bottom-logo {
        margin-left: 15%;
    }
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 3.5%;
        left: 10.5%;
        border-radius: 24px;
        padding: 10px;
        width: 79%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (min-width: 1800px) and (max-width: 2200px) {
    .footer-content {
        height: 100%;
        width: 90.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .footer-bottom-logo {
        margin-left: 13%;
    }
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 3.5%;
        left: 10.5%;
        border-radius: 24px;
        padding: 10px;
        width: 79%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (min-width: 2200px) {
    .footer-content {
        height: 100%;
        width: 86%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .footer-bottom-logo {
        margin-left: 11%;
    }
    .footer-rounded {
        position: absolute;
        z-index: 1;
        bottom: 3.5%;
        left: 10%;
        border-radius: 24px;
        padding: 10px;
        width: 78%;
        background: linear-gradient(135deg, #102341, #1d4380, #b0315d);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}


/* CONTROLLING THE WIDTH OF THE CONTENT */

@media screen and (min-width: 1500px) {
    .footer-rounded {
        width: 78%;
    }
    .footer-title-wrapper {
        width: 85%;
    } 
    .footer-subtitle-wrapper {
        width: 55%;
    }
}

@media screen and (min-width: 1800px) {
    .footer-rounded {
        width: 70%;
        left: 14%;
    }
    .footer-title-wrapper {
        width: 78%;
    } 
    .footer-subtitle-wrapper {
        width: 55%;
    }
}

@media screen and (min-width: 2000px) {
    .footer-rounded {
        width: 60%;
        left: 19%;
    }
    .footer-title-wrapper {
        width: 75%;
    } 
    .footer-subtitle-wrapper {
        width: 55%;
    }
}