.vertical-tab-container {
    position: relative;
    width: 40%;
    height: 360px; 
    display: flex;
    color: black;
    margin-left: 57px;
}

.tabs {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tabs-wrapper {
    width: 100%;
    padding-left: 200px;
    padding-bottom: 150px;
    display: flex;
    flex-direction: row;
    min-height: 500px;
    max-height: 500px;
}

.tab {
    flex: 1;
    text-align: left;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    position: relative;
    z-index: 2;
}

.slider-container {
    position: absolute;
    z-index: 0;
    left: 0;
    width: 3px;
    height: 100%;
    border-radius: 3px;
    background-color: #164276;
    opacity: 10%;
}

.vertical-slider {
    position: absolute;
    left: 0;
    margin-left: -1px;
    border-radius: 3px;
    width: 5px;
    height: 20%;
    margin-top: 15px;
    margin-bottom: 0px;
    background-color: #FF2F48;
    transition: transform 0.3s ease;
    transform: translateZ(0);
    z-index: 1;
}

.tab-content-container {
    margin-left: 30px;
    max-height: 65px;
    min-height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    white-space: pre-line;
    padding-right: 100px;
}

.tab-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
    margin-top: -5px;
    width: 65%;
    text-align: left;
}

.transition-group-container {
    width: 45%;
    max-height: 550px;
    min-height: 550px;
    position: relative;
    overflow: hidden;
}

.tab-font-size {
    font-size: 24px;
}

.check-image {
    position: absolute;
    top: 30px;
    left: 0px;
}

.opac-enter {
    opacity: 0;
    z-index: 1;
}
  
.opac-enter.opac-enter-active {
    opacity: 1;
    transition: opacity 300ms linear ; 
}
  
.opac-exit {
    opacity: 0.5;
}
  
.opac-exit.opac-exit-active {
    opacity: 0;
    transition: opacity 100ms linear;
}

.opac-exit-done {
    opacity: 0;
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .transition-group-container {
        width: 45%;
        max-height: 450px;
        min-height: 450px;
        position: relative;
        overflow: hidden;
        margin-left: 50px;
    }
    .tab-font-size {
        font-size: 22px;
    }
    .check-image {
        position: absolute;
        top: 27px;
        left: 0px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .transition-group-container {
        width: 45%;
        max-height: 500px;
        min-height: 500px;
        position: relative;
        overflow: hidden;
        margin-left: 70px;
    }
    .tab-font-size {
        font-size: 20px;
    }
    .check-image {
        position: absolute;
        top: 24px;
        left: 0px;
    }
    .vertical-tab-container {
        position: relative;
        width: 40%;
        height: 360px; 
        display: flex;
        color: black;
        margin-left: 0px;
    }
}

@media screen and (min-width: 607px) and  (max-width: 768px) {
    .transition-group-container {
        width: 85%;
        max-height: 500px;
        min-height: 500px;
        position: relative;
        overflow: hidden;
        margin-left: -50px;
        margin-top: 100px;
        margin-bottom: 0px;
    }
    .tab-font-size {
        font-size: 18px;
    }
    .check-image {
        position: absolute;
        top: 20px;
        left: 0px;
    }
    .vertical-tab-container {
        position: relative;
        width: 60%;
        height: 360px; 
        display: flex;
        color: black;
        margin-left: -250px;
        margin-top: 200px;
    }
    .tabs-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 800px;
        max-height: 800px;
        margin-top: -100px;
    }
    .tab-content {
        width: 75%
    }
}

@media screen and (min-width: 429px) and (max-width: 606px) {
    .transition-group-container {
        width: 80%;
        max-height: 500px;
        min-height: 500px;
        position: relative;
        overflow: hidden;
        margin-left: -150px;
        margin-top: 100px;
        margin-bottom: 0px;
    }
    .tab-font-size {
        font-size: 18px;
    }
    .check-image {
        position: absolute;
        top: 20px;
        left: 0px;
    }
    .vertical-tab-container {
        position: relative;
        width: 75%;
        height: 360px; 
        display: flex;
        color: black;
        margin-left: -200px;
        margin-top: 200px;
    }
    .tabs-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 800px;
        max-height: 800px;
        margin-top: -100px;
    }   
    .tab-content {
        width: 90%
    }
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .transition-group-container {
        width: 85%;
        max-height: 450px;
        min-height: 450px;
        position: relative;
        overflow: hidden;
        margin-left: -150px;
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .tab {
        width: '100%';
    }
    .tab-font-size {
        font-size: 16px;
    }
    .check-image {
        position: absolute;
        top: 18px;
        left: 0px;
    }
    .vertical-tab-container {
        position: relative;
        width: 75%;
        height: 360px; 
        display: flex;
        color: black;
        margin-left: -200px;
        margin-top: 200px;
    }
    .tabs-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 800px;
        max-height: 800px;
        margin-top: -100px;
    }  
    .tab-content {
        width: 85%
    } 
}

/*LARGER SCREENS*/

@media screen and (min-width: 1280px) and (max-width: 1500px) {
    .tabs-wrapper {
        width: 100%;
        padding-left: 200px;
        padding-bottom: 150px;
        display: flex;
        flex-direction: row;
        min-height: 400px;
        max-height: 400px;
        margin-left: 2%;
    }
    .tab-content-container {
        margin-left: 30px;
        max-height: 65px;
        min-height: 65px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 90%;
        white-space: pre-line;
        padding-right: 100px;
    }
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
    .tabs-wrapper {
        width: 100%;
        padding-left: 200px;
        padding-bottom: 150px;
        display: flex;
        flex-direction: row;
        min-height: 400px;
        max-height: 400px;
        margin-left: 5%;
    }
}

@media screen and (min-width: 1800px) and (max-width: 2200px) {
    .tabs-wrapper {
        width: 100%;
        padding-left: 200px;
        padding-bottom: 150px;
        display: flex;
        flex-direction: row;
        min-height: 400px;
        max-height: 400px;
        margin-left: 7%;
    }
}

@media screen and (min-width: 2200px) {
    .tabs-wrapper {
        width: 100%;
        padding-left: 200px;
        padding-bottom: 150px;
        display: flex;
        flex-direction: row;
        min-height: 400px;
        max-height: 400px;
        margin-left: 10%;
    }
}

/* CONTROLLING THE WIDTH OF THE TABS */

@media screen and (min-width: 1500px) {
    .tabs-wrapper {
        width: 90%;
    }
    .tab-content-container {
        width: 90%;
    }
    .transition-group-container {
        width: 45%;
    }
}

@media screen and (min-width: 1800px) {
    .tabs-wrapper {
        width: 90%;
    }
    .tab-content-container {
        width: 80%;
    }
    .transition-group-container {
        width: 40%;
    }
}

@media screen and (min-width: 2000px) {
    .tabs-wrapper {
        width: 80%;
    }
    .tab-content-container {
        width: 75%;
    }
    .transition-group-container {
        width: 37%;
    }
}