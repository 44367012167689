.industries-background {
    min-height: 400px;
    max-height: 400px;
    width: 100%;
    background: radial-gradient(circle at 15% 100%, rgba(27, 104, 192, 0.15), transparent 80%),
    radial-gradient(circle at 95% 100%, rgb(255, 47, 71, 0.15), transparent 60%);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.industries-title {
    font-size: 48px;
    font-weight: 800;
    color: #444645;
    margin-top: 40px;
}

.industry-title {
    font-weight: 400;
    font-size: 24px;
    color: #5A605F;
    text-align: center;
}

.industry-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 13%;
    margin-right: 13%;
}

.industry-image {
    height: 48px;
}

@media screen and (min-width: 646px) and  (max-width: 768px) {
    .industry-item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        width: 85%;
        margin-top: -50px;
        margin-left: 8%;
        margin-right: 8%;
    }
    .industry-title {
        font-weight: 400;
        font-size: 18px;
        color: #5A605F;
    }
    .industry-image {
        width: 35px;
    }
    .industries-title {
        font-size: 40px;
        margin-top: 100px;
    }
}

@media screen and (min-width: 429px) and  (max-width: 645px) {
    .industry-item-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        width: 100%;
        margin-top: -50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .industry-title {
        font-weight: 400;
        font-size: 18px;
        color: #5A605F;
    }
    .industries-title {
        font-size: 40px;
        margin-top: 50px;
    }
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .industry-item-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        width: 100%;
        margin-top: -50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .industry-title {
        font-weight: 400;
        font-size: 14px;
        color: #5A605F;
    }
    .industry-image {
        width: 35px;
    }
    .industries-title {
        font-size: 38px;
        margin-top: 50px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .industry-item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-top: 20px;
        margin-left: 4%;
        margin-right: 4%;
    }
    .industry-title {
        font-weight: 400;
        font-size: 20px;
        color: #5A605F;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .industry-item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-top: 20px;
        margin-left: 10%;
        margin-right: 10%;
    }
}



/* CONTROLLING THE WIDTH OF THE CONTENT */

@media screen and (min-width: 1500px) {
    .industry-item-wrapper { 
        width: 70%;
    }
    .industries-background {
        align-items: center;
    }
}

@media screen and (min-width: 1800px) {
    .industry-item-wrapper { 
        width: 70%;
    }
    .industries-background {
        align-items: center;
    }
}

@media screen and (min-width: 2000px) {
    .industry-item-wrapper { 
        width: 60%;
    }
    .industries-background {
        align-items: center;
    }
}