.services-container {
    width: 100%;
    margin-top: 70px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.services-title-container {
    width: 57%;   
    text-align: left;
}

.services-title-wrapper {
    margin-left: 160px;
    margin-right: 160px;
}

.services-title {
    font-size: 48px;
    font-weight: 800;
    color: #444645;
}

.services-subtitle-container {
    width: 70%;   
    text-align: left;
}

.services-subtitle {
    font-size: 24px;
    font-weight: 200;
    color: #5A605F;
}

.services-items-wrapper {
    color: black;
    margin-left: 10%;
    width: 50%;
    height: 400px;
}

.services-content-wrapper {
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    /*height: 400px;*/
}

.services-image-wrapper {
    height: fit-content;
    width: 50%;
    margin-left: -200px;
}

.services-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.services-item-animated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@keyframes smooth-appear {
    to{
      max-height: 160px;
      opacity: 1;
    }
  }

.services-item-upper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 30px;
}

@keyframes smooth-appear2 {
    to{
        transform: translateY(0px);
        opacity: 1;
    }
  }
.services-item-lower {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    /* opacity: 0; */
    /* transform: translateY(-50px); */
    

    /* animation: smooth-appear2 0.3s ease-in forwards; */

}

.services-item-lower {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.3s ease-out, opacity 0.1s ease-out;
}

.services-item-lower.active {
    opacity: 1;
    max-height: 160px; /* Adjust this value based on your content */
    transition: max-height 0.3s ease-in, opacity 0.1s ease-in;
}
.services-item-icon-wrapper {
    width: 35px;

    /* background-color: red; */
}
.services-item-title { 
    margin-left: 20px;
    text-align: left;
}

.services-item-subtitle {
    margin-top: 0px;
    width: 75%;
    margin-left: 70px;
    font-size: 24px;
    font-weight: 200;
    color: #5A605F;
}

.services-selected {
    font-size: 24px;
    font-weight: 500;
    color: #5A605F;
}

.services-unselected {
    font-size: 24px;
    font-weight: 200;
    color: #AEB2B1;   
}

.fade-enter {
    opacity: 0;
    z-index: -10;
}
  
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear ; 
    z-index: -10;
}
  
.fade-exit {
    opacity: 0.5;
    z-index: -10;
}
  
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 100ms linear;
    z-index: -10;
}

.fade-exit-done {
    opacity: 0;
    z-index: -10;
}

.services-transition-image-div {
    width: 400px;
    height: 343px;
    overflow: hidden;
    z-index: -50;
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .services-items-wrapper {
        color: black;
        margin-left: 8%;
        width: 50%;
        height: 400px;
    }
    .services-title-wrapper {
        margin-left: 100px;
    } 
    .services-items-wrapper {
        margin-left: 60px;
        margin-right: 100px;
    }  
    .services-content-wrapper {
        margin-left: 0px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .services-item-lower.active {
        opacity: 1;
        max-height: 260px; /* Adjust this value based on your content */
        transition: max-height 0.3s ease-in, opacity 0.4s ease-in;
    }
    .services-title-wrapper {
        margin-left: 50px;
    } 
    .services-content-wrapper {
        margin-left: 0px;
    }
    .services-items-wrapper {
        margin-left: 0px;
        margin-right: 140px
    }  
}

@media screen and (min-width: 429px) and  (max-width: 768px) {
    .services-container {
        margin-top: 0px;
        margin-bottom: -80px;
    }
    .services-title-wrapper {
        margin-left: 50px;
        margin-right: 0px;
    }
    .services-title {
        font-size: 38px;
    }
    .services-title-container {
        width: 80%;
    }
    .services-items-wrapper {
        color: black;
        margin-left: 50px;
        width: 100%;
        height: 400px;
    }
    .services-content-wrapper {
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 20px;
    }
    .services-transition-image-div {
        width: 150px;
        height: 343px;
        overflow: visible;
        z-index: -50;
        margin-top: -50px;
    }
    .fading-image {
        width: 350px;
    }
    .services-subtitle {
        font-size: 16px;
    }
    .services-item-subtitle {
        font-size: 16px;
    }
    .services-selected {
        font-size: 16px;
    }
    .services-unselected {
        font-size: 16px;
    }
    @keyframes smooth-appear {
        to{
          max-height: 260px;
          opacity: 1;
        }
      }
      .services-item-lower.active {
        opacity: 1;
        max-height: 260px; /* Adjust this value based on your content */
        transition: max-height 0.3s ease-in, opacity 0.4s ease-in;
    }
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .services-title-wrapper {
        margin-left: 20px;
        margin-right: 0px;
    }
    .services-title {
        font-size: 38px;
    }
    .services-title-container {
        width: 80%;
    }
    .services-items-wrapper {
        color: black;
        margin-left: 20px;
        width: 100%;
        height: 400px;
    }
    .services-content-wrapper {
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin-top: 20px;
    }
    .services-transition-image-div {
        width: 100%;
        height: 343px;
        overflow: visible;
        z-index: -50;
        margin-top: 0px;
    }
    .services-image-wrapper {
        margin-left: -15%;
        margin-top: 0px;
    }
    .fading-image {
        width: 250px;
    }
    .services-subtitle {
        font-size: 16px;
    }
    .services-item-subtitle {
        font-size: 16px;
    }
    .services-selected {
        font-size: 16px;
    }
    .services-unselected {
        font-size: 16px;
    }
    .services-container {
        margin-bottom: -300px;
        margin-top: 0px;
    }
    @keyframes smooth-appear {
        to{
          max-height: 260px;
          opacity: 1;
        }
      }
      .services-item-lower.active {
        opacity: 1;
        max-height: 260px; /* Adjust this value based on your content */
        transition: max-height 0.3s ease-in, opacity 0.4s ease-in;
    }
}

/*LARGER SCREENS*/

@media screen and (min-width: 1280px) and (max-width: 1500px) {
    .services-items-wrapper {
        color: black;
        margin-left: 6.5%;
        width: 50%;
        height: 400px;
    }
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
    .services-items-wrapper {
        color: black;
        margin-left: 5.25%;
        width: 50%;
        height: 400px;
    }
}

@media screen and (min-width: 1800px) and (max-width: 2200px) {
    .services-items-wrapper {
        color: black;
        margin-left: 4.25%;
        width: 50%;
        height: 400px;
    }
    .services-item-subtitle {
        width: 70%;
    }
}

@media screen and (min-width: 2200px) {
    .services-items-wrapper {
        color: black;
        margin-left: 3.5%;
        width: 50%;
        height: 400px;
    }
    .services-item-subtitle {
        width: 65%;
    }
}



/* CONTROLLING THE WIDTH OF THE CONTENT */

@media screen and (min-width: 1500px) {
    .services-container {
        width: 90%;
    }
}

@media screen and (min-width: 1800px) {
    .services-container {
        width: 85%;
    }
}

@media screen and (min-width: 2000px) {
    .services-container {
        width: 80%;
    }
}