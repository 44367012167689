.configs-container {
    background: linear-gradient(135deg, #102341 -60%, #1d4380 30%, #b0315d 90%);
    background-repeat: no-repeat;
    width: 80%;
    height: fit-content;
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 200px;
}

.configs-upper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

@keyframes smooth-appear-height {
    to{
      height: 700px;
      opacity: 1;
    }
  }

.configs-lower {
    width: 160%;
    height: 0px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    margin-top: 50px;
    margin-left: -30%;
    border-top: 1px solid #ffffff5d;
    border-top-left-radius: 100%450px;
    border-top-right-radius: 100%450px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    opacity: 0;
}

.configs-lower-animated {
    animation: smooth-appear-height 1s ease-in-out forwards;
}

.configs-title {
    color: white;
    font-size: 48px;
    font-weight: 800;
}

.configs-subtitle {
    color: #F2F7FD;
    font-size: 24px;
    font-weight: 200;
}

.configs-items {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-left: 20%;
    margin-top: 50px;
}

.configs-items-lower-wrapper {
    width: 60%; 
    margin-top: 50px;
    margin-left: -2%;
}

.configs-text-upper {
    font-size: 24px;
    font-weight: lighter;
    color: #F2F7FD;
}

.configs-text-bottom {
    font-size: 24px;
    font-weight: lighter;
    color: #F2F7FD;
}

.configs-text-lower {
    font-size: 24px;
    margin-top: -20px;
    font-weight: bolder;
    color: white;
}

.configs-text-holder {
    margin-left: 50px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: 429px) and  (max-width: 768px) {
    .configs-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 0%;
        margin-top: 50px;
    }
    .configs-text-upper {
        font-size: 16px;
        text-align: left;
    }
    .configs-text-lower {
        font-size: 16px;
        text-align: left;
        margin-top: -10px;
    }
    .configs-text-bottom {
        font-size: 16px;
    }
    .configs-lower {
        border-top-left-radius: 100%150px;
        border-top-right-radius: 100%150px; 
        margin-bottom: -200px;
    }
    .configs-items-lower-wrapper {
        margin-top: -300px;
        margin-left: 1%;
    }
    .configs-text-holder {
        margin-left: 20px;
        width: 50%;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .configs-title {
        font-size: 42px;
        width: 90%;
    }
    .configs-container {
        width: 85%;
        margin-top: -100px;
    }
    .configs-subtitle {
        font-size: 16px;
    }
    @keyframes smooth-appear-height {
        to{
          height: 1020px;
          opacity: 1;
        }
      }
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .configs-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 0%;
        margin-top: 50px;
    }
    .configs-text-upper {
        font-size: 16px;
        text-align: left;
    }
    .configs-text-lower {
        font-size: 16px;
        text-align: left;
        margin-top: -10px;
    }
    .configs-text-bottom {
        font-size: 16px;
    }
    .configs-lower {
        border-top-left-radius: 100%150px;
        border-top-right-radius: 100%150px; 
        margin-bottom: -200px;
    }
    .configs-items-lower-wrapper {
        margin-top: -300px;
        margin-left: 1%;
    }
    .configs-text-holder {
        margin-left: 20px;
        width: 50%;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .configs-title {
        font-size: 38px;
        width: 90%;
    }
    .configs-container {
        width: 85%;
        margin-top: -100px;
    }
    .configs-subtitle {
        font-size: 16px;
    }
    @keyframes smooth-appear-height {
        to{
          height: 1020px;
          opacity: 1;
        }
      }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .configs-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0%;
        margin-top: 100px;
        text-align: center;
    }
    .configs-text-upper {
        font-size: 24px;
        font-weight: lighter;
        color: #F2F7FD;
        margin-top: 100px;
    }
    .configs-items-lower-wrapper {
        margin-top: -100px;
    }
    .configs-text-holder {
        margin-left: 0px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    @keyframes smooth-appear-height {
        to{
          height: 1050px;
          opacity: 1;
        }
      }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    
}


/*LARGE SCREENS*/


@media screen and (min-width: 1280px) and (max-width: 1500px) {
    .configs-items-lower-wrapper {
        width: 60%; 
        margin-top: 50px;
        margin-left: 3%;
    }   
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
    .configs-items-lower-wrapper {
        width: 60%; 
        margin-top: 50px;
        margin-left: 2.5%;
    }   
}

@media screen and (min-width: 1800px) and (max-width: 2200px) {
    .configs-items-lower-wrapper {
        width: 60%; 
        margin-top: 50px;
        margin-left: 9%;
    }   
}

@media screen and (min-width: 2200px) {
    .configs-items-lower-wrapper {
        width: 60%; 
        margin-top: 50px;
        margin-left: 9%;
    }   
}

/*CONTROLLING THE WIDTH*/

@media screen and (min-width: 1500px) {
    .configs-container {
        width: 70%;
    }
}  

@media screen and (min-width: 1800px) {
    .configs-container {
        width: 70%;
    }
}  

@media screen and (min-width: 2000px) {
    .configs-container {
        width: 60%;
    }
}  