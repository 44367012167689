.cookies-content-container {
    height: 384px;
    width: 496px;
    position: fixed;
    bottom: 50px;
    right: 50px;
    border: 1px #E6E7E7 solid;
    background-color: white;
    border-radius: 16px;
    padding: 30px !important;
    z-index: 500;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.cookies-close {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.cookies-title-wrapper {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.cookies-title {
    color: #3B3E3E;
    font-size: 24px;
    font-weight: 400;
    margin-top: 30px;
}

.cookies-text-wrapper {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.cookies-text {
    color: black;
    font-size: 16px;
    font-weight: 200;
    text-align: left;
    margin-right: 10px;
    margin-left: 10px;
}

.second-text {
    margin-top: 20px
}

.cookies-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.cookies-button {
    border: 1px #3B3E3E solid;
    border-radius: 12px;
    width: 208px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cookies-button-white {
    background-color: white;
    color: #3B3E3E;
    margin-left: 20px;
}

.cookies-button-black {
    background-color: #3B3E3E;
    color: white;
    margin-right: 20px;
}

.cookie-button-text {
    font-size: 16px;
    font-weight: 800;
}

@media screen and (max-width: 657px) {
    .cookies-content-container {
        width: 70%;
        height: fit-content;
        bottom: 2%;
        right: 2%;
    }
    .cookies-buttons-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .cookies-button-white {
        background-color: white;
        color: #3B3E3E;
        margin-left: 0px;
    }
    
    .cookies-button-black {
        background-color: #3B3E3E;
        color: white;
        margin-right: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}