/**#certificate-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 50px;
    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
}**/

#certificate-slider-container {
    height: '100%';
    width: '100%';
    display: flex;
    justify-content: space-around;
    margin-left: 0px;
    margin-right: 0px;
}

.carousel-primary,
.carousel-secondary {
    top: 10%;
    left: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
}

.carousel-primary div {
    width: 100%;
    margin-left: 10px;
}

.carousel-primary {
    display: flex;
    justify-content: space-around;
    animation: scroll-horizontal 30s linear infinite;
    animation-delay: -15s;
}

.carousel-secondary {
    animation: scroll-horizontal2 30s linear infinite;
    animation-delay: 0s;
}

@keyframes scroll-horizontal {
   from {
    left: -100%;
   }

   to {
    left: 100%;
   }
}

@keyframes scroll-horizontal2 {
    from {
        left: -100%;
    }

    to {
        left: 100%;
    }
}

.carousel-primary:hover,
.carousel-primary:hover~.carousel-secondary {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}

.carousel-secondary:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}

#certificate-slider-container:hover>.carousel-primary:not(:hover) {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}

#certificate-slider-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

#certificate-image {
    width: 74px;
}

#certificate-slider-container div:hover {
    margin-right: 40px;
    margin-left: 40px;
    transition: "margin-left 0.4s ease-in-out, margin-right 0.4s ease-in-out";
}

.certificate-div-left {
    min-width: 1300px;
    min-height: 20px;
}

@media screen and (min-width: 429px) and  (max-width: 768px) {
    .certificate-div-left {
        min-width: 2070px;
        min-height: 20px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .certificate-div-left {
        min-width: 1850px;
        min-height: 20px;
    }
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .certificate-div-left {
        min-width: 2350px;
        min-height: 20px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .certificate-div-left {
        min-width: 1500px;
        min-height: 20px;
    }
}