.testimonials-container {
    color: black;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;
    margin-top: 100px;
}

.testimonials-divider {
    background: linear-gradient(to right, #1b68c04e, #1b68c04e, #ff2f4752, #ff2f4752);
    height: 1px;
    width: 100%;
}

.testimonial-image-container {
    margin-top: 100px;
}

.testimonial-image-container-gray {
    margin-top: 100px;
}

.testimonial-image-container-light {
    margin-top: 100px;
}

.testimonial-image-gray {
    width: 102px;
    filter: grayscale(100%);
    opacity: 0.8;
    cursor: pointer;
}

.testimonial-image-light {
    width: 86px;
    filter: grayscale(100%);
    opacity: 0.5;
}

.testimonial {
    font-size: 16px;
    font-weight: 200;
    color: #5A605F;
}

.testimonial-name {
    font-size: 24px;
    font-weight: 500;
    color: #444645;
}

.testimonial-title {
    font-size: 24px;
    font-weight: 200;
    color: #858B8A;
    margin-top: -10px;
}

.testimonial-container {
    border: 1px solid #E3ECFB;
    border-radius: 24px;
    padding: 20px;
    width: 45%;
}

.testimonial-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
    height: 250px;
}

.testimonial-carousel-container {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .testimonial-carousel-container {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .testimonial-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: -50px;
        height: 550px;
    } 
    .testimonial-head {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .testimonial-name {
        font-size: 16px;
    }
    .testimonial-title {
        font-size: 16px;
    }
    .testimonial-container {
        width: 70%;
    }
    .testimonials-container {
        margin-top: 0px;
    }
}

@media screen and (min-width: 429px) and (max-width: 518px) {
    .testimonial-carousel-container {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .testimonial-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: -50px;
        height: 500px;
    } 
    .testimonial-head {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .testimonial-name {
        font-size: 16px;
    }
    .testimonial-title {
        font-size: 16px;
    }
    .testimonial-container {
        width: 70%;
    }
    .testimonials-container {
        margin-top: 0px;
    }
}

@media screen and (min-width: 519px) and  (max-width: 768px) {
    .testimonial-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: -50px;
        height: 450px;
    } 
    .testimonial-carousel-container {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .testimonial-head {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .testimonial-name {
        font-size: 16px;
    }
    .testimonial-title {
        font-size: 16px;
    }
    .testimonial-container {
        width: 70%;
    }
    .testimonials-container {
        margin-top: 20px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .testimonial-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 80px;
        height: 350px;
    }
    .testimonial-carousel-container {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    
}



/* CONTROLLING THE WIDTH OF THE CONTENT */

@media screen and (min-width: 1500px) {
    .testimonial-wrapper {
        width: 95%;
    }
}

@media screen and (min-width: 1800px) {
    .testimonial-wrapper { 
        width: 80%;
    }
}

@media screen and (min-width: 2000px) {
    .testimonial-wrapper { 
        width: 65%;
    }
}