.team-background {
    background-image: url('../../images/team-background-gradient.svg');
    background-size: cover;
    background-position-y: 180px;
    background-repeat: no-repeat;
    width: 100%;
    margin-top: 100px;
    z-index: 0;
}

.team-upper {
    display: flex;
    flex-direction: row;
    margin-left: 4%;
}

.team-member-image {
    width: 279px;
}

.team-member-image-cf {
    width: 282px;
}

.team-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 40%;
    margin-left: 2%;
    margin-top: 80px;
}

.team-title {
    font-weight: 800;
    font-size: 48px;
    color: #444645;
}

.team-subtitle-upper {
    font-weight: 200;
    font-size: 24px;
    color: #F2F7FD;
    margin-top: 50px;
}

.team-subtitle-lower {
    font-weight: 200;
    font-size: 24px;
    color: #F2F7FD;
    margin-top: 10px;
}

.team-lower {
    margin-top: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 250px;
}

.team-ceo {
    position: relative;
    height: 425px;
    width: 464px;
    margin-bottom: 200px;
}

.team-cf {
    position: relative;
    height: 425px;
    width: 464px;
    margin-bottom: 200px;
}

.team-member-image-container {
    height: 400px;
    width: 420px;
}

.team-job-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 48px;
    background-color: white;
    width: 314px;
    margin-left: 15%;
    cursor: pointer;
    margin-top: 141px;
    z-index: 500;
}

.team-job-title {
    font-weight: 500;
    font-size: 24px;
    color: #444645;
}

.team-title-description {
    font-size: 24px;
    font-weight: 200;
    font-style: italic;
    color: white;
}

.ceo-title-container {
    position: absolute;
    right: 0px;
    top: 15%;
    text-align: left;
}

.cf-title-container {
    position: absolute;
    left: -180px;
    top: 35%;
    text-align: right;
}

.team-ceo-name {
    font-weight: 500;
    font-size: 24px;
    color: white
}

.team-ceo-title {
    font-weight: 200;
    font-size: 16px;
    color: white;
    margin-top: -20px;
}

.fade-team-img-enter {
    position: absolute;
    top: 0px;
    left: 10px;
    opacity: 0;
    z-index: 10;
}
  
.fade-team-img-enter.fade-team-img-enter-active {
    opacity: 1;
    transition: opacity 900ms ease-in ; 
    z-index: 10;
}
  
.fade-team-img-exit {
    position: absolute;
    top: 0px;
    left: 10px;
    opacity: 1;
    z-index: 10;
}
  
.fade-team-img-exit.fade-team-img-exit-active {
    opacity: 0;
    transition: opacity 600ms ease-out;
    z-index: 10;
}

.fade-team-img-exit-done {
    opacity: 0;
    z-index: 10;
}

.team-title-image {
    width: 560px;
    z-index: 10;
}
.team-image-container {
    margin-top: 100px;
    position: relative;
}
.team-transition-image-div {
    width: 580px;
    height: 280px;
    overflow: hidden;
    z-index: 10;
    position: relative;
}
.team-img-white-background {
    background-color: white;
    top: 0px; 
    left: 14.5px;
    width: 551px; 
    height: 271px; 
    position: absolute;
    border-radius: 35px; 
    z-index: -1;
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .team-upper {
        margin-left: 1.5%;
    }
    .team-lower {
        margin-top: 150px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-left: 0%;
        margin-right: 5%;
        margin-bottom: 250px;
    }
    .team-cf {
        position: relative;
        height: 425px;
        width: 464px;
        margin-right: -8%;
        margin-bottom: 200px;
    }
    .team-title {
        font-weight: 800;
        font-size: 40px;
        color: #444645;
    }
    
    .team-subtitle-upper {
        font-weight: 200;
        font-size: 18px;
        margin-top: -30px;
    }
    
    .team-subtitle-lower {
        font-weight: 200;
        font-size: 18px;
        color: #F2F7FD;
        margin-top: 1%;
    }
    .team-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 40%;
        margin-left: 2%;
        margin-top: 100px;
    }
}

@media screen and (min-width: 932px) and (max-width: 1024px) {
    .team-lower {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 250px;
    }   
    .team-title {
        font-weight: 800;
        font-size: 40px;
        color: #F2F7FD;
    }
    
    .team-subtitle-upper {
        font-weight: 200;
        font-size: 18px;
        margin-top: -30px;
    }
    
    .team-subtitle-lower {
        font-weight: 200;
        font-size: 18px;
        color: #F2F7FD;
        margin-top: 5%;
    }
    .team-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 70%;
        margin-left: 0%;
        margin-top: -30px;
    }
    .team-upper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0%;
    }
    .team-cf {
        margin-top: 100px;
    }
}

@media screen and (min-width: 658px) and  (max-width: 932px) {
    .team-lower {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 250px;
    }   
    .team-title {
        font-weight: 800;
        font-size: 40px;
        color: #F2F7FD;
    }
    .team-subtitle-upper {
        font-weight: 200;
        font-size: 18px;
        margin-top: -30px;
    }
    .team-subtitle-lower {
        font-weight: 200;
        font-size: 18px;
        color: #F2F7FD;
        margin-top: 20px;
    }
    .team-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 70%;
        margin-left: 0%;
        margin-top: -30px;
    }
    .team-upper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0%;
    }
    .team-ceo {
        margin-top: 100px;
    }
    .team-cf {
        margin-top: 250px;
    }
    .ceo-title-container {
        position: absolute;
        right: 35%;
        top: -25%;
        text-align: center;
    }
    .cf-title-container {
        position: absolute;
        left: 20%;
        top: -25%;
        text-align: center;
    }
}

@media screen and (min-width: 429px) and (max-width: 657px) {
    .team-member-image {
        width: 250px;
    }
    .team-job-title-container {
        margin-top: 85px;
    }
    .team-job-title-container-mob-2 {
        margin-top: 80px;
    }
    .team-background {
        margin-top: 300px;
    }
    .team-lower {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 150px;
    }   
    .team-title {
        font-weight: 800;
        font-size: 40px;
        color: #444645;
        margin-top: -140px;
        margin-bottom: 20px;
    }

    .team-image-container {
        margin-top: 0px;
    }
    
    .team-subtitle-upper {
        font-weight: 200;
        font-size: 18px;
        margin-top: -30px;
    }
    
    .team-subtitle-lower {
        font-weight: 200;
        font-size: 18px;
        color: #F2F7FD;
        margin-top: 20px;
    }
    .team-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 70%;
        margin-left: 0%;
        margin-top: -30px;
    }
    .team-upper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0%;
        margin-top: 80px;
    }
    .team-title-image {
        width: 430px;
        margin-bottom: 100px;
    }
    .team-ceo {
        margin-top: 100px;
    }
    .team-cf {
        margin-top: 200px;
    }
    .ceo-title-container {
        position: absolute;
        right: 35%;
        top: -25%;
        text-align: center;
    }
    .cf-title-container {
        position: absolute;
        left: 22%;
        top: -25%;
        text-align: center;
    }
    .team-transition-image-div {
        width: 450px;
        height: 215px;
        overflow: hidden;
        z-index: 10;
        margin-bottom: 100px;
    }
    .team-img-white-background {
        top: 0px; 
        left: 13px;
        width: 424px; 
        height: 208px; 
        border-radius: 25px;
    }
    .fade-team-img-enter {
        left: 2.2%;
    }
    .fade-team-img-exit {
        left: 2.2%;
    }
}

@media screen and (min-width: 300px) and (max-width: 428px) {
    .team-member-image {
        width: 200px;
    }
    .team-job-title-container {
        margin-top: -13px;
    }
    .team-job-title-container-mob-2 {
        margin-top: -16px;
    }
    .team-background {
        margin-top: 300px;
    }
    .team-lower {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 0px;
    }   
    .team-title {
        font-weight: 800;
        font-size: 33px;
        color: #444645;
        margin-top: -40px;
        margin-bottom: 20px;
    }

    .team-image-container {
        margin-top: 0px;
    }
    
    .team-subtitle-upper {
        font-weight: 200;
        font-size: 18px;
        margin-top: -30px;
    }
    
    .team-subtitle-lower {
        font-weight: 200;
        font-size: 18px;
        color: #F2F7FD;
        margin-top: 20px;
    }
    .team-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 70%;
        margin-left: 0%;
        margin-top: -30px;
    }
    .team-upper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0%;
        margin-top: 50px;
    }
    .team-title-image {
        width: 300px;
        margin-bottom: 100px;
    }
    .cf-title-container {
        position: absolute;
        left: 10%;
        top: 25%;
        text-align: left;
    }
    .team-job-title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 48px;
        background-color: white;
        width: 284px;
        margin-left: 20%;
    }
    .team-job-title {
        font-weight: 500;
        font-size: 18px;
        color: #444645;
    }
    .team-title-description {
        font-size: 16px;
        font-weight: 200;
        font-style: italic;
        color: white;
    }
    
    .team-ceo-name {
        font-weight: 500;
        font-size: 16px;
        color: white
    }
    
    .team-ceo-title {
        font-weight: 200;
        font-size: 12px;
        color: white;
        margin-top: -20px;
    }

    .team-ceo {
        margin-top: 100px;
    }
    .team-cf {
        margin-top: 50px;
    }
    .ceo-title-container {
        position: absolute;
        right: 35%;
        top: -25%;
        text-align: center;
    }
    .cf-title-container {
        position: absolute;
        left: 30%;
        top: -25%;
        text-align: center;
    }
    .team-transition-image-div {
        width: 305px;
        height: 150px;
        overflow: hidden;
        z-index: 10;
        margin-bottom: 100px;
    }
    .team-img-white-background {
        top: 0px; 
        left: 4.5px;
        width: 296px; 
        height: 146px; 
        border-radius: 20px;
    }
    .fade-team-img-enter {
        left: 1%;
    }
    .fade-team-img-exit {
        left: 1%;
    }
}


/*LARGER SCREENS*/

@media screen and (min-width: 1280px) and (max-width: 1500px) {
    .team-title {
        font-weight: 800;
        font-size: 40px;
        color: #444645;
    }
    .team-upper {
        margin-left: 10%;
    }   
    .team-subtitle-upper {
        font-weight: 200;
        font-size: 20px;
        margin-top: -20px;
    }    
    .team-subtitle-lower {
        font-weight: 200;
        font-size: 20px;
        color: #F2F7FD;
        margin-top: 1%;
    }
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
    .team-title {
        font-weight: 800;
        font-size: 44px;
        color: #444645;
    }
    .team-upper {
        margin-left: 12%;
    }    
    .team-subtitle-upper {
        font-weight: 200;
        font-size: 22px;
        margin-top: -30px;
    }   
    .team-subtitle-lower {
        font-weight: 200;
        font-size: 22px;
        color: #F2F7FD;
        margin-top: 1%;
    }
    .team-transition-image-div {
        width: 720px;
        height: 330px;
    }
    .team-img-white-background {
        top: 0px; 
        left: 40px;
        width: 640px; 
        height: 315px; 
        border-radius: 40px; 
    }
    .fade-team-img-enter {
        left: 4.9%;
    }
    .fade-team-img-exit {
        left: 4.9%;
    }
}

@media screen and (min-width: 1800px) and (max-width: 2200px) {
    .team-title {
        font-weight: 800;
        font-size: 44px;
        color: #444645;
    }
    .team-upper {
        margin-left: 18%;
    }    
    .team-subtitle-upper {
        font-weight: 200;
        font-size: 22px;
        margin-top: -30px;
    }    
    .team-subtitle-lower {
        font-weight: 200;
        font-size: 22px;
        color: #F2F7FD;
        margin-top: 1%;
    }
    .team-transition-image-div {
        width: 720px;
        height: 330px;
    }
    .team-img-white-background {
        top: 0px; 
        left: 40px;
        width: 640px; 
        height: 315px; 
        border-radius: 40px; 
    }
    .fade-team-img-enter {
        left: 4.9%;
    }
    .fade-team-img-exit {
        left: 4.9%;
    }
}

@media screen and (min-width: 2200px) {
    .team-title {
        font-weight: 800;
        font-size: 44px;
        color: #444645;
    }
    .team-upper {
        margin-left: 22%;
    }
    .team-subtitle-upper {
        font-weight: 200;
        font-size: 22px;
        margin-top: -30px;
    }
    .team-subtitle-lower {
        font-weight: 200;
        font-size: 22px;
        color: #F2F7FD;
        margin-top: 1%;
    }
    .team-transition-image-div {
        width: 720px;
        height: 330px;
    }
    .team-img-white-background {
        top: 0px; 
        left: 40px;
        width: 640px; 
        height: 315px; 
        border-radius: 40px; 
    }
    .fade-team-img-enter {
        left: 4.9%;
    }
    .fade-team-img-exit {
        left: 4.9%;
    }
}


/* CONTROLLING THE WIDTH OF THE CONTENT */

@media screen and (min-width: 1500px) {
    .team-subtitle-upper { 
        width: 90%;
    }
    .team-subtitle-lower { 
        width: 90%;
    }
    .team-title-image {
        width: 650px;

    }
    .team-lower {
        width: 70%;
        margin-left: 15%;
    }
}

@media screen and (min-width: 1650px) {
    .team-lower {
        width: 75%;
    }
}

@media screen and (min-width: 1800px) {
    .team-subtitle-upper { 
        width: 80%;
    }
    .team-subtitle-lower { 
        width: 80%;
    }
    .team-lower {
        width: 72%;
    }
}

@media screen and (min-width: 2000px) {
    .team-subtitle-upper { 
        width: 75%;
    }
    .team-subtitle-lower { 
        width: 75%;
    }
    .team-lower {
        width: 57%;
        margin-left: 22%;
    }
}