@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

html {
  overflow-x: hidden;
  width: 100%;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.gradient-background {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle at 100% 0%, rgba(27, 104, 192, 0.25), transparent 50%),
    radial-gradient(circle at -30% 120%, rgb(255, 47, 71, 0.25), transparent 50%);
}

.partly-red-gradient {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle at -45% -35%, rgba(255, 47, 71, 0.25), transparent 50%),
    radial-gradient(circle at 15% 100%, rgba(27, 104, 192, 0.15), transparent 50%),
    radial-gradient(circle at 75% 100%, rgb(255, 47, 71, 0.07), transparent 50%);
}

.columnize {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font {
  font-family: 'Roboto', sans-serif;
}

#terms-content h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #444645;
  margin-top: 50px;
}

#terms-content p {
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  font-size: 24px;
  color: #5A605F;
}

#terms-content ul li {
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  font-size: 24px;
  color: #5A605F;
}

#terms-content ul li span {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #5A605F;
}

.light-text {
  color: #2A2C2C;
  font-size: 24px;
  font-weight: 100;
}

.header-text {
  color: black;
  font-size: 64px;
  font-weight: 800;
}

.gradient-text {
  background: linear-gradient(to right, #1B67C0, #1B67C0, #FF2F48, #FF2F48);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 64px;
  font-weight: 800;
}

#head-text-container p {
  margin-bottom: -50px;
}

#subtitle-text-container {
  width: 80%;
}

#subtitle-text-container p {
  margin-top: 100px;
}

#expert-text, #cdk-text {
  margin-top: -20px;
}

p #gradient {
  background: linear-gradient(to right, #1B67C0, #FF2F48);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#logo {
  width: 183px;
  margin-top: 164px;
}

#partner-logo {
  margin-top: 50px;
  margin-bottom: 150px;
  transition: margin 0.3s;
  cursor: pointer;
}

.rounded-button {
  border-radius: 12px;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 800;
  height: 48px;
  width: 210px;
  background-color: #FF2F48;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 25px;
  transition: width 0.3s, height 0.3s, box-shadow 0.3s;
  white-space: nowrap;
}

.rounded-button:hover {
  width: 225px;
  height: 53px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.rounded-button:hover + #partner-logo {
  margin-top: 45px;
}

#separator {
  background: linear-gradient(to right, #1b68c07b, #1B67C0, #FF2F48, #ff2f477d);
  height: 1px;
  width: 100%;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  min-height: 75px;
  max-height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 500;
  backdrop-filter: blur(10px);
  overflow-x: hidden;
}

.chevron-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .chevron-button {
      margin-left: 20px;
      margin-right: 20px;
    }
    .sticky-header {
      overflow-x: hidden;
    }
    .header-text {
      font-size: 48px;
    }  
    .gradient-text {
      font-size: 48px;
    }
    #subtitle-text-container {
      margin-left: 5%;
      margin-right: 5%;
    }
    #ops-text-container {
      margin-left: 5%;
      margin-right: 5%;
    }
    #head-text-container {
      margin-left: 5%;
      margin-right: 5%;
    }
    .light-text {
      font-size: 16px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .header-text {
      font-size: 54px;
    }  
    .gradient-text {
      font-size: 54px;
    }
}

@media screen and (min-width: 1500px) and (max-width: 1849px) {
    #subtitle-text-container {
      width: 65%;
    }
}

@media screen and (min-width: 1850px) {
    #subtitle-text-container {
      width: 50%;
    }
}